import { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';

import { uniq } from 'lodash';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Button, CssBaseline, Divider, FormGroup, List, ListItem, Paper, TextField, Typography, FormControl, Input, InputLabel } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import { listTeams } from './graphql/queries';
import { createEvent, updateEvent, updateTeam } from './graphql/mutations';
import { listEvents } from './graphql/queries';
import {  API, GRAPHQL_AUTH_MODE} from '@aws-amplify/api';
import { Event, UpdateEventInput, CreateEventMutation, UpdateEventMutation, ListTeamsQuery, ListEventsQuery, Team } from './API';

import { ThemeProvider} from '@mui/material/styles';
import theme from './theme';
import './logo.css';

import awsconfig from './updatedAwsConfig';
Amplify.configure(awsconfig);


export function EventManagement() {

  const { user } = useAuthenticator((context) => [context.user]);

  const [event, setEvent] = useState<Event | undefined>(undefined);

  const [events, setActiveEvents] = useState<Array<Event>>([]);

  const [eventName, setEventName] = useState<string>('change me');

  const [ teams, setTeams ] = useState<Array<Team>>([]);

  const [isSuccessMinDistance, setIsSuccessMinDistance] = useState(false);

  const [ readyToStart, setReadyToStart ] = useState<boolean>(false);

  const [ updatedEventInput, setUpdatedEventInput ] = useState<UpdateEventInput>();

  const compareEventsByCreatedAt = (a: Event, b: Event): number => {
    if (Date.parse(a.createdAt) < Date.parse(b.createdAt)) {
      return 1;
    } else if (Date.parse(a.createdAt) > Date.parse(b.createdAt)) {
      return -1;
    } else {
      return 0;
    }
  }

  const startEvent =async (e:any) => {
    e.preventDefault();

    const apiName = 'startEvent';
    const path = '/';
    const myInit = {
      body: {
        event: {
          name: eventName,
          id: event?.id,
        },
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };

    try {

      const response = await API.post(apiName, path, myInit);
      console.log(JSON.stringify(response));

      const startedEvent: UpdateEventInput = {
        id: event!.id,
        started: true,
      }

      await API.graphql<GraphQLQuery<UpdateEventMutation>>({query: updateEvent, variables: {input: startedEvent}});



    } catch (error) {
      console.log(JSON.stringify(error));
    }

  }

  const archiveEvent = async (item: Event) => {

    const archiveEvent: UpdateEventInput = {
      id: item!.id,
      archived: true,
    }
    await API.graphql<GraphQLQuery<UpdateEventMutation>>({
      query: updateEvent,
      variables: {input: archiveEvent}
    });

    // get the list of teams for the event
    const listTeamsResult = await API.graphql<GraphQLQuery<ListTeamsQuery>>({
      query: listTeams,
      authMode: GRAPHQL_AUTH_MODE.API_KEY
    });

    // for each team, set the team locked to false
    listTeamsResult.data?.listTeams?.items?.forEach(async (team) => {
      const unlockTeamResult = await API.graphql({
        query: updateTeam,
        variables: {input: {id: team?.id, locked: false}},
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });

      console.log(unlockTeamResult);
    });

  }

  useEffect(() => {
    // declare the data fetching function

    console.log('use effect running');


    const fetchEvent = async () => {
      let event: Event;
        try {
            const myEventResult = await API.graphql<GraphQLQuery<ListEventsQuery>>({
              query: listEvents,
              authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            });

            if (myEventResult.data!.listEvents!.items) {
              setActiveEvents((myEventResult.data!.listEvents!.items as Event[]).filter((item: any) => {return (!item.archived && item.started);}));
            }
           
            
            if (myEventResult.data!.listEvents!.items.filter((item: any) => {return (!item.archived && !item.started);}).length > 0) {
              event = (myEventResult.data!.listEvents!.items.filter((item: any) => {return (!item.archived && !item.started);}) as Event[]).sort(compareEventsByCreatedAt)[0];

              console.log(event);

              return event;
            }
        } catch (err) {
          throw err;
        }

    }

    const fetchTeams = async (event: Event | undefined) => {
      if (!event) {
        return;
      }

      let teams: Array<Team> = [];
      const listTeamsResult = await API.graphql<GraphQLQuery<ListTeamsQuery>>({
        query: listTeams,
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });
      console.log(listTeamsResult);
      teams = (listTeamsResult.data?.listTeams?.items as Array<Team>).filter((team) => event?.teams?.includes(team!.id));
      setTeams(teams);

      // if there is at least one team and all teams have at least one member and the minimiumDistance is set, set readyToStart to true
      setReadyToStart((teams.length > 0) && (teams.every((team) => team?.members?.length ? team?.members?.length > 0 : false) && (event?.minimumDistance! > 0)));
      console.log(readyToStart);

      return event;
    }
    
    const setTheEvent = async (event: Event | undefined) => {
      setEvent(event);
      if (event!.name) {
        setEventName(event!.name!);
      }
    }
    fetchEvent().then((event) => fetchTeams(event)).then((event) => setTheEvent(event)).catch((error) => console.log(error));

  }, [user, readyToStart]);


  const setEventNameSubmit = async (e: any) => {
    e.preventDefault();
    if (event) { // event exists, mutate
      console.log(event);
      const updatedEventInput: UpdateEventInput = {
        id: event.id,
        name: eventName,
      };

      const updateEventResult = await API.graphql<GraphQLQuery<UpdateEventMutation>>({
        query: updateEvent,
        variables: { input: updatedEventInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      });

      setEvent(updateEventResult.data!.updateEvent as Event);
      setEventName(updateEventResult.data!.updateEvent!.name!);

    } else { // event doesn't exist, create
      console.log('creating event');

      try {
        const createEventInput = {
          name: eventName,
        };

        const createEventResult = await API.graphql<GraphQLQuery<CreateEventMutation>>({
          query: createEvent,
          variables: { input: createEventInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });

        setEvent(createEventResult.data!.createEvent as Event);
        setEventName(createEventResult.data!.createEvent!.name!);


    } catch (e) {
      console.log(e);
    }


    }
}

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='logo-container'>
        <div className='logo'></div>
      </div>
    <Paper elevation={0} sx={{ width: '95%', m: 'auto', mt: '20px'}}>
      <Typography variant="h3" gutterBottom>Hello {user?.attributes?.name}{event ? ` running the event called ${event.name}` : ''}</Typography>

      <form onSubmit={setEventNameSubmit}>
        <FormGroup>
          <TextField type="text" 
                  color="primary" 
                  defaultValue="change me"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)
                  } />
            
          <Button variant="outlined" color="primary" type="submit">{event ? <>Change event name</> : <>Set Event Name</>}</Button>
        </FormGroup>
      </form>
      {(event?.name && teams) ? (<>
        <Typography variant="body1" gutterBottom>Currently signed up teams:</Typography>
        <List sx={{
          //listStyleType: 'disc',
          listStylePosition: 'inside'
        }}>
                  {uniq(teams).map((team) => 
          <ListItem sx={{ display: 'list-item' }} key={team.id}>{team.name}, {team.members}
          </ListItem>)}
          

        </List>
      </>) : (<></>)}


      {event && !event?.started ? (
        <>
          <FormControl>
  <InputLabel htmlFor="minimum-distance">Minimum Distance</InputLabel>
  <Input id="minimum-distance" 
         type="text" 
         color="primary" 
         defaultValue={event.minimumDistance}
         onChange={(e) => {
           const updatedEventInput: UpdateEventInput = {
             id: event.id,
             minimumDistance: Number(e.target.value),
           };
           // Store the updatedEventInput in a state variable
           setUpdatedEventInput(updatedEventInput);
         }} />
</FormControl>

<Button variant="contained" color={isSuccessMinDistance ? 'success' : 'primary' } onClick={async () => {
  await API.graphql<GraphQLQuery<UpdateEventMutation>>({
    query: updateEvent,
    variables: { input: updatedEventInput },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  });
  setIsSuccessMinDistance(true);
  setTimeout(() => { 
    setIsSuccessMinDistance(false);
  }, 1500);
}}>Set</Button></>) : (<></>)}
       {event && !event?.started && readyToStart ? (<>
        <form onSubmit={async (e) => {
          e.preventDefault();
          await startEvent(e);
        }}>
        <FormGroup>
          <Button variant="outlined" color="primary" type="submit">Start the event</Button>
        </FormGroup>
      </form>
      </>) : (<></>)}


      {(events.map((item) => {

        return (
        <>
        <Divider sx={{height: '4px'}}/>
        <Box sx={{ display: 'flex' }} key={item.id}>

          {item.name}&nbsp;&nbsp;

        
          <form onSubmit={async (e: any) => 
            {
              e.preventDefault();
              await archiveEvent(item);
              // reload the page
              setTimeout(() => {
                window.location.reload();
              }, 2000);   
            }}>
            <FormGroup>
              <Button variant="outlined" color="primary" type="submit">Archive the event</Button>
            </FormGroup>
          </form>
        </Box></>)}))}
     </Paper>
     </ThemeProvider>
  );
}

Hub.listen('auth', (data) => { 
  //if (data.payload.event === 'signIn_failure') {
      console.log(data);
  //}
})

export default EventManagement;